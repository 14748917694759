import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/chatbot.css';  // Importar los estilos del chatbot

const Chatbot = () => {
  const [messages, setMessages] = useState([
    { text: "¡Hola! Soy el asistente de SpikAI. ¿En qué puedo ayudarte hoy?", sender: "bot" }
  ]);
  const [userInput, setUserInput] = useState("");
  const [businessInfo, setBusinessInfo] = useState(null);

  // Cargar la información del negocio desde el JSON
  useEffect(() => {
    axios.get('/businessInfo.json').then((response) => {
      setBusinessInfo(response.data);
    });
  }, []);

  const handleSendMessage = async () => {
    if (!userInput.trim()) return; // No enviar si el campo de entrada está vacío
  
    const newMessages = [...messages, { text: userInput, sender: "user" }];
    setMessages(newMessages);
  
    const prompt = `
      La siguiente es información sobre la empresa:
      Nombre: ${businessInfo?.companyName}
      Misión: ${businessInfo?.mission}
      Servicios: ${businessInfo?.services?.join(", ")}
      
      Cliente: ${userInput}
      Asistente:`;
  
    try {
      const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
  
      if (!apiKey) {
        console.error("API Key de OpenAI no encontrada.");
        setMessages([...newMessages, { text: "Lo siento, hubo un problema con la configuración. Inténtalo de nuevo más tarde.", sender: "bot" }]);
        return;
      }
  
      // Verifica que la URL es correcta
      const response = await axios.post(
        "https://api.openai.com/v1/completions",  // Asegúrate de que esta URL es correcta
        {
          model: "text-davinci-003",  // O el modelo GPT que prefieras
          prompt: prompt,
          max_tokens: 100,
          temperature: 0.7,
        },
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );
  
      const botResponse = response.data.choices[0].text.trim();
      setMessages([...newMessages, { text: botResponse, sender: "bot" }]);
    } catch (error) {
      console.error("Error al obtener la respuesta de OpenAI:", error);
      setMessages([...newMessages, { text: "Lo siento, hubo un problema con el servidor. Inténtalo de nuevo.", sender: "bot" }]);
    }
  
    setUserInput("");
  };
  

  return (
    <div className="chat-container">
      <div className="chat-box">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`message ${message.sender === "bot" ? "bot-message" : "user-message"}`}
          >
            {message.text}
          </div>
        ))}
      </div>
      <div className="input-area">
        <input
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder="Escribe tu mensaje..."
        />
        <button onClick={handleSendMessage}>Enviar</button>
      </div>
    </div>
  );
};

export default Chatbot;
