import React from 'react';

const FeatureCard = ({ title, description }) => (
  <div className="p-6 border-2 border-transparent hover:border-orange-500 transition-all duration-300 shadow-md rounded-lg text-center bg-white dark:bg-gray-800 transform hover:scale-105">
    <h3 className="text-2xl font-bold mb-4 text-blue-500 dark:text-blue-400">{title}</h3>
    <p className="text-gray-700 dark:text-gray-300">{description}</p>
  </div>
);


const Features = () => {
  const features = [
    {
      title: "Automatización Inteligente",
      description: "Respuestas automáticas personalizadas basadas en IA para atender a tus clientes 24/7."
    },
    {
      title: "Personalización Avanzada",
      description: "Adapta las conversaciones al historial y preferencias de cada cliente para una experiencia única."
    },
    {
      title: "Análisis en Tiempo Real",
      description: "Obtén insights valiosos sobre las interacciones con tus clientes para mejorar tu servicio."
    }
  ];

  return (
    <section id="caracteristicas" className="mb-12 py-8 bg-white dark:bg-gray-900">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8 text-center text-blue-500 dark:text-blue-400">Características principales</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <FeatureCard key={index} title={feature.title} description={feature.description} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
