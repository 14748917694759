import React from 'react';

const Contact = () => {
  return (
    <section id="contacto" className="py-12 bg-white dark:bg-gray-900 text-center">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-6 text-blue-500 dark:text-blue-400">Contacta con nosotros</h2>
        <p className="mb-6 text-gray-700 dark:text-gray-300 max-w-2xl mx-auto">
          ¿Listo para llevar tu comunicación empresarial al siguiente nivel? Contáctanos hoy mismo para una demostración gratuita.
        </p>
        <a
          href="mailto:info@spikai.com"
          className="inline-block bg-orange-500 hover:bg-orange-600 text-white font-medium px-6 py-3 rounded transition-colors duration-300"
        >
          Solicitar demo
        </a>
      </div>
    </section>
  );
};

export default Contact;
