import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-300 py-4 mt-12">
      <div className="container mx-auto px-4 text-center">
        <p className="font-light">&copy; 2024 SpikAI. Todos los derechos reservados.</p>
      </div>
    </footer>
  );
};

export default Footer;
