import React from 'react';

const UseCaseCard = ({ industry, description, features }) => (
  <div className="p-6 border-2 border-transparent hover:border-orange-500 transition-all duration-300 shadow-md rounded-lg bg-white dark:bg-gray-800 transform hover:scale-105" data-aos="fade-up">
    <h3 className="text-2xl font-bold text-blue-500 dark:text-blue-400 mb-4">{industry}</h3>
    <p className="text-gray-700 dark:text-gray-300 mb-4">{description}</p>
    <ul className="list-disc list-inside text-gray-700 dark:text-gray-300">
      {features.map((feature, index) => (
        <li key={index}>{feature}</li>
      ))}
    </ul>
  </div>
);

const UseCases = () => {
  const useCases = [
    {
      industry: 'E-commerce',
      description: 'Automatiza las consultas de productos, seguimiento de envíos y atención al cliente para ofrecer respuestas rápidas y personalizadas.',
      features: [
        'Automatización de consultas de productos',
        'Seguimiento de pedidos en tiempo real',
        'Soporte al cliente las 24 horas',
      ],
    },
    {
      industry: 'Servicios Financieros',
      description: 'Optimiza la atención al cliente en bancos y aseguradoras, proporcionando respuestas rápidas sobre productos financieros y manejo de consultas complejas.',
      features: [
        'Consulta automática de saldos',
        'Respuestas rápidas sobre productos financieros',
        'Atención a preguntas frecuentes sobre cuentas y seguros',
      ],
    },
    {
      industry: 'Salud',
      description: 'Gestiona recordatorios de citas, respuestas a preguntas frecuentes de pacientes y automatiza la asistencia para mejorar la experiencia del paciente.',
      features: [
        'Recordatorios de citas automatizados',
        'Consulta de disponibilidad de médicos',
        'Automatización de respuestas a preguntas frecuentes de salud',
      ],
    },
  ];

  return (
    <section id="casos-de-uso" className="py-12 bg-gray-100 dark:bg-gray-900">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-blue-500 dark:text-blue-400 mb-8">Casos de Uso en Diversas Industrias</h2>
        <p className="text-center text-gray-700 dark:text-gray-300 mb-12 max-w-2xl mx-auto">
          SpikAI se adapta a las necesidades de múltiples sectores, ofreciendo soluciones de automatización inteligente para optimizar la comunicación en cada industria.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {useCases.map((useCase, index) => (
            <UseCaseCard 
              key={index}
              industry={useCase.industry}
              description={useCase.description}
              features={useCase.features}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default UseCases;
