import React from 'react';

const PricingCard = ({ title, price, features }) => (
  <div className="bg-white dark:bg-gray-800 p-6 border-2 border-transparent hover:border-orange-500 transition-all duration-300 shadow-md rounded-lg text-center">
    <h3 className="text-2xl font-bold mb-2 text-blue-500 dark:text-blue-400">{title}</h3>
    <p className="text-3xl font-bold mb-4 text-blue-500 dark:text-blue-400">{price}</p>
    <ul className="mb-6 text-gray-700 dark:text-gray-300">
      {features.map((feature, index) => (
        <li key={index} className="mb-2">{feature}</li>
      ))}
    </ul>
    <a
      href="#contacto"
      className="inline-block bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded transition-colors duration-300"
    >
      Elegir Plan
    </a>
  </div>
);

const Pricing = () => {
  const plans = [
    {
      title: "Básico",
      price: "29€/mes",
      features: [
        "Hasta 500 conversaciones/mes",
        "Automatización básica",
        "Soporte por email"
      ]
    },
    {
      title: "Pro",
      price: "99€/mes",
      features: [
        "Hasta 2000 conversaciones/mes",
        "Automatización avanzada",
        "Análisis básico",
        "Soporte prioritario"
      ]
    },
    {
      title: "Empresa",
      price: "Personalizado",
      features: [
        "Conversaciones ilimitadas",
        "Automatización personalizada",
        "Análisis avanzado",
        "Soporte 24/7",
        "Integración personalizada"
      ]
    }
  ];

  return (
    <section id="precios" className="mb-12 py-8 bg-white dark:bg-gray-900">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8 text-center text-blue-500 dark:text-blue-400">Planes de Precios</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {plans.map((plan, index) => (
            <PricingCard key={index} title={plan.title} price={plan.price} features={plan.features} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Pricing;
