const Hero = () => {
  return (
    <section className="relative bg-gray-900 text-white h-screen flex items-center justify-center text-center">
      {/* Fondo decorativo */}
      <div className="absolute inset-0 bg-cover bg-center opacity-20" style={{ backgroundImage: 'url("/your-background-image.jpg")' }}></div>
      
      {/* Contenido del Hero */}
      <div className="relative z-10" data-aos="fade-up" data-aos-duration="1000">
        <h1 className="text-6xl md:text-7xl lg:text-8xl font-extrabold tracking-tight">
          Bienvenido a <span className="text-blue-500">Spik</span><span className="text-orange-500">AI</span>
        </h1>
        <p className="text-2xl md:text-3xl mt-6 max-w-3xl mx-auto text-gray-300 font-light">
          SpikAI es una solución innovadora que revoluciona la comunicación empresarial a través de WhatsApp.
        </p>
        <button className="mt-8 px-8 py-4 bg-orange-500 text-white text-xl rounded-full shadow-lg hover:bg-orange-600 hover:scale-105 transform transition duration-300">
          ¡Empieza ahora!
        </button>
      </div>
    </section>
  );
};

export default Hero;
