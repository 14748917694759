import React, { useState, useEffect } from 'react';
import HeaderNavigation from './HeaderNavigation';
import Hero from './Hero';
import Features from './Features';
import Pricing from './Pricing';
import Contact from './Contact';
import Footer from './Footer';
import AOS from 'aos'; // Animaciones
import 'aos/dist/aos.css'; // Estilos de AOS
import UseCases from './UseCases';
// import SuccessStories from './SuccessStories';
import Chatbot from './Chatbot'; // Importar el chatbot

const LandingPage = () => {
  const [darkMode, setDarkMode] = useState(true);

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  const toggleDarkMode = () => setDarkMode(!darkMode);

  return (
    <div className={`min-h-screen font-sans ${darkMode ? 'dark' : ''}`}>
      <div className="bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 transition-colors duration-300">
        {/* Header con navegación combinada */}
        <HeaderNavigation darkMode={darkMode} toggleDarkMode={toggleDarkMode} />

        {/* Contenido principal */}
        <main className="container mx-auto px-4 py-8" style={{ paddingTop: '150px' }}>
          <section data-aos="fade-up">
            <Hero />
          </section>
          <section className="mt-12" data-aos="fade-up">
            <Features />
          </section>
          <section className="mt-12" data-aos="fade-up">
            <UseCases />
          </section>
          {/* Sección del chatbot */}
          <section className="mt-12" data-aos="fade-up">
            <div className="bg-gray-100 dark:bg-gray-800 p-8 rounded-lg shadow-lg">
              <h2 className="text-3xl font-bold text-center mb-8">
                ¡Habla con nuestro Asistente Virtual!
              </h2>
              <Chatbot /> {/* Aquí se coloca el chatbot */}
            </div>
          </section>
          <section className="mt-12" data-aos="fade-up">
            {/* <SuccessStories /> */} {/* Comentar la referencia al componente */}
          </section>
          <section className="mt-12" data-aos="fade-up">
            <Pricing />
          </section>
          <section className="mt-12" data-aos="fade-up">
            <Contact />
          </section>
        </main>
        {/* Footer */}
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
