import React, { useState } from 'react';
import { Link } from 'react-scroll';

const navItems = [
  { id: 'inicio', label: 'Inicio' },
  { id: 'caracteristicas', label: 'Características' },
  { id: 'precios', label: 'Precios' },
  { id: 'contacto', label: 'Contacto' },
];

const HeaderNavigation = ({ darkMode, toggleDarkMode }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className={`fixed w-full z-50 py-4 shadow-md ${darkMode ? 'bg-gray-800' : 'bg-white'} transition-colors duration-300`}>
      <div className="container mx-auto px-4 flex justify-between items-center">
        {/* Logo en la izquierda */}
        <a href="/" className="flex items-center">
          <img src="/logo.png" alt="Logo de la web" className="w-24 h-auto" /> {/* Ajusta el tamaño según tu diseño */}
        </a>

        {/* Menú de navegación para pantallas grandes */}
        <nav className="hidden lg:flex lg:items-center lg:space-x-8">
          <ul className="flex space-x-6">
            {navItems.map((item) => (
              <li key={item.id}>
                <Link
                  to={item.id}
                  smooth={true}
                  duration={500}
                  spy={true}
                  activeClass="text-orange-500"
                  className="text-gray-700 dark:text-gray-100 hover:text-blue-500 dark:hover:text-orange-500 transition-colors duration-300 cursor-pointer font-medium"
                  aria-label={`Ir a la sección ${item.label}`}
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </nav>

        {/* Botón de modo oscuro y menú de hamburguesa */}
        <div className="flex items-center space-x-4">
          {/* Icono de menú móvil */}
          <button
            className="lg:hidden text-gray-700 dark:text-gray-100 focus:outline-none"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Abrir menú de navegación"
          >
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>

          {/* Botón de alternar modo oscuro */}
          <button
            onClick={toggleDarkMode}
            className={`p-2 rounded-full transition-colors duration-300 shadow-md ${darkMode ? 'bg-orange-500 text-white' : 'bg-blue-500 text-white'}`}
            aria-label="Alternar modo oscuro"
          >
            {darkMode ? '🌞' : '🌙'}
          </button>
        </div>

        {/* Menú desplegable para pantallas móviles */}
        {isMenuOpen && (
          <nav className="lg:hidden absolute top-16 left-0 w-full bg-white dark:bg-gray-800 shadow-lg">
            <ul className="flex flex-col items-center space-y-4 py-4">
              {navItems.map((item) => (
                <li key={item.id}>
                  <Link
                    to={item.id}
                    smooth={true}
                    duration={500}
                    spy={true}
                    activeClass="text-orange-500"
                    className="text-gray-700 dark:text-gray-100 hover:text-blue-500 dark:hover:text-orange-500 transition-colors duration-300 cursor-pointer font-medium"
                    onClick={() => setIsMenuOpen(false)} // Cierra el menú al hacer clic
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        )}
      </div>
    </header>
  );
};

export default HeaderNavigation;
